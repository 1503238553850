import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import SubMasterEditForm from 'manage-tritag/components/pages/sub-masters/edit-form'

const SubMasterEditPage = (props: PageProps) => {
  return (
    <Layout label1="Sub Master" label2="Update" url="/sub-masters">
      <SubMasterEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(SubMasterEditPage)
